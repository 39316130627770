import { EmailIcon } from "outline-icons";
import * as React from "react";
import styled from "styled-components";
import Flex from "@shared/components/Flex";
import { s } from "@shared/styles";
import ButtonLarge from "~/components/ButtonLarge";
import Fade from "~/components/Fade";
import Heading from "~/components/Heading";

import PageTitle from "~/components/PageTitle";
import Text from "~/components/Text";

import { draggableOnDesktop } from "~/styles";
import { SendedEmailProps } from "./types";

function SendedEmail(props: SendedEmailProps) {
  const { emailLinkSentTo, onBack } = props;

  return (
    <Background>
      <Centered align="center" justify="center" column auto>
        <PageTitle title="Cheque seu e-mail" />
        <CheckEmailIcon size={38} />
        <Heading centered>Cheque seu e-mail</Heading>
        <Note>
          Um link mágico de login foi enviado para o e-mail{" "}
          <em>{emailLinkSentTo}</em> se existir uma conta.
        </Note>
        <br />
        <ButtonLarge onClick={onBack} fullwidth neutral>
          Voltar para o login
        </ButtonLarge>
      </Centered>
    </Background>
  );
}

const Centered = styled(Flex)`
  user-select: none;
  width: 90vw;
  height: 100%;
  max-width: 320px;
  margin: 0 auto;
`;

const CheckEmailIcon = styled(EmailIcon)`
  margin-bottom: -1.5em;
`;

const Background = styled(Fade)`
  width: 100vw;
  height: 100%;
  background: ${s("background")};
  display: flex;
  ${draggableOnDesktop()}
`;

const Note = styled(Text)`
  color: ${s("textTertiary")};
  text-align: center;
  font-size: 14px;
  margin-top: 8px;

  em {
    font-style: normal;
    font-weight: 500;
  }
`;

export { SendedEmail };
