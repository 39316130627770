import { action } from "mobx";
import WhichAIQuery from "~/models/WhichAI";
import type { WhichAIResult } from "~/types";
import { client } from "~/utils/ApiClient";
import RootStore from "./RootStore";
import Store, { RPCAction } from "./base/Store";

export interface WhichAICreateDocumentProps {
  documentIds: string[];
  prompt: string;
  docProps?: Record<string, any | undefined>;
}

export interface WhichAICollectionQueryProps {
  query: string;
  collectionId?: string;
}

export interface WhichAIQueryProps {
  query: string;
  documentId: string;
}

export default class WhichAIStore extends Store<WhichAIQuery> {
  actions = [RPCAction.List, RPCAction.Create];

  constructor(rootStore: RootStore) {
    super(rootStore, WhichAIQuery);
  }

  @action
  query = async (payload: WhichAIQueryProps): Promise<WhichAIResult> => {
    const { query, documentId } = payload;
    const res = await client.post("/whichai.document.query", {
      query,
      documentId,
    });

    return res;
  };

  @action
  collectionQuery = async (
    payload: WhichAICollectionQueryProps
  ): Promise<WhichAIResult> => {
    const { query, collectionId } = payload;
    const res = await client.post("/whichai.collection.query", {
      query,
      collectionId,
    });

    return res;
  };

  @action
  createDocumentWithAi = async (
    payload: WhichAICreateDocumentProps
  ): Promise<{
    ok: boolean;
    response?: { data: string | null };
    message: string | null;
  }> => {
    const { documentIds, docProps, prompt } = payload;
    const res = await client.post(
      "/whichai.create",
      {
        documentIds,
        docProps,
        prompt,
      },
      {
        retry: false,
      }
    );
    return res;
  };
}
