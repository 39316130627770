import React, { useState } from "react";

function Signature() {
  const [plan, setPlan] = useState("basic");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Aqui você pode implementar a lógica para enviar o formulário ao backend
    window.console.log("Plan:", plan);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="p-4 flex flex-col rounded-lg shadow-lg w-full max-w-md bg-zinc-900 gap-2">
        <h2 className="text-2xl font-bold text-center">Escolha seu Plano</h2>
        <div className="flex flex-col gap-2 py-4 text-zinc-300">
          <span className="text-center">
            Estamos felizes em ver que você está aproveitando o Which.AI
          </span>
          <span className="text-center">
            Para continuar com acesso completo e desbloquear todas as
            funcionalidades, escolha o plano que melhor se adapta às suas
            necessidades.
          </span>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div>
            <label className="block text-sm font-medium" htmlFor="plan">
              Selecione o Plano
            </label>
            <select
              id="plan"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm appearance-none"
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              required
            >
              <option value="basic">Básico</option>
              <option value="premium">Premium</option>
              <option value="enterprise">Enterprise</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export { Signature };
