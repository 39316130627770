import { t } from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";

import styled from "styled-components";
import Flex from "@shared/components/Flex";
import { s } from "@shared/styles";
import ButtonLarge from "~/components/ButtonLarge";
import ChangeLanguage from "~/components/ChangeLanguage";
import Fade from "~/components/Fade";
import OutlineIcon from "~/components/Icons/OutlineIcon";
import InputLarge from "~/components/InputLarge";
import LoadingIndicator from "~/components/LoadingIndicator";
import PageTitle from "~/components/PageTitle";
import useStores from "~/hooks/useStores";
import { draggableOnDesktop } from "~/styles";
import { detectLanguage } from "~/utils/language";
import BackButton from "./Login/components/BackButton";

const Signup = () => {
  const { auth } = useStores();
  const { config } = auth;
  const [email, setEmail] = useState<string>("");

  if (!config) {
    return <LoadingIndicator />;
  }

  return (
    <Background>
      <BackButton config={config} />
      <ChangeLanguage locale={detectLanguage()} />

      <Centered align="center" justify="center" gap={12} column auto>
        <PageTitle
          title={config.name ? `${config.name} – ${t("Login")}` : t("Login")}
        />
        <Logo>
          <OutlineIcon size={48} />
        </Logo>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input
            type="text"
            name="name"
            placeholder="Nome"
            value={email}
            onChange={(ev) => setEmail(ev.target.value ?? "")}
            disabled={false}
            autoFocus
            required
            short
          />
          <Input
            type="text"
            name="lastName"
            placeholder="Sobrenome"
            value={email}
            onChange={(ev) => setEmail(ev.target.value ?? "")}
            disabled={false}
            autoFocus
            required
            short
          />
          <Input
            type="email"
            name="email"
            placeholder="me@domain.com"
            value={email}
            onChange={(ev) => setEmail(ev.target.value ?? "")}
            disabled={false}
            autoFocus
            required
            short
          />
        </div>
        <ButtonLarge type="submit" disabled={false}>
          {t("Sign Up")} →
        </ButtonLarge>
      </Centered>
    </Background>
  );
};

const Background = styled(Fade)`
  width: 100vw;
  height: 100%;
  background: ${s("background")};
  display: flex;
  ${draggableOnDesktop()}
`;

const Logo = styled.div`
  margin-bottom: -4px;
`;

const Centered = styled(Flex)`
  user-select: none;
  width: 90vw;
  height: 100%;
  max-width: 320px;
  margin: 0 auto;
`;

const Input = styled(InputLarge)`
  width: 100%;
`;

export default observer(Signup);
