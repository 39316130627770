import Model from "./base/Model";

class WhichAIQuery extends Model {
  static modelName = "WhichAI";

  /**
   * The query string, automatically truncated to 255 characters.
   */
  query: string;

  /**
   * Where the query originated.
   */
  source: "api" | "app" | "slack";
}

export default WhichAIQuery;
